// Results.js
import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { GrPrevious, GrNext } from "react-icons/gr";
import BounceLoader from 'react-spinners/BounceLoader';

import ManipulationChart from './ManipulationChart';
import '../GlobalStyles.css';

const Results = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [mp4File, setMp4File] = useState(null);
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState({
        test_vf_xfer: false,
        test_vf_df: true,
        test_batfd: false
    });
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const inputRef = useRef();
    const [tests, setTests] = useState({
        test_vf_xfer: false,
        test_vf_df: true,
        test_batfd: false
    });
    const [chartData, setChartData] = useState({
        test_vf_xfer: null,
        test_vf_df: null,
        test_batfd: null
    });
    const [selectedTest, setSelectedTest] = useState('test_vf_df');
    const [imageUrls, setImageUrls] = useState({
        test_vf_xfer: [],
        test_vf_df: [],
        test_batfd: []
    });

    const testEndpoints = {
        test_vf_df: '/analyze_df'
        // test_batfd: '/analyze_audio',
        // test_vf_xfer: '/analyze_xfer'
    };

    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    useEffect(() => {
        const fetchVideo = async () => {
            try {
                const response = await fetch(`https://frontendtovm.azurewebsites.net/api/getMP4/${uuid}`);
                // const response = `http://localhost:5000/api/getMP4/${uuid}`;
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setMp4File(url);
                // console.log('Video URL:', url);
            } catch (error) {
                console.error('Error fetching video:', error);
            }
        };
        fetchVideo();
    }, []);

    // useEffect(() => {
    //     const fileObject = location.state?.file;
    //     const selectedTests = location.state?.tests;
    //     const formData = new FormData();
    //     formData.append('file', fileObject);

    //     const fetchResults = async () => {
    //         await Promise.all(Object.entries(testEndpoints)
    //             .filter(([test]) => selectedTests[test])
    //             .map(async ([test, endpoint]) => {
    //                 endpoint = `https://frontendtovm.azurewebsites.net${endpoint}`;
    //                 // endpoint = `http://localhost:5000${endpoint}`
    //                 setLoading(prev => ({ ...prev, [test]: true }));
    //                 try {
    //                     const response = await axios.post(endpoint, formData, {
    //                         headers: { 'Content-Type': 'multipart/form-data' },
    //                     });
    //                     // console.log("VM response data: ", response.data);
    //                     setResults(prev => ({ ...prev, [test]: response.data }));
    //                     setLoading(prev => ({ ...prev, [test]: false }));

    //                     if (response.data.frameScores) {
    //                         prepareChartData(response.data.frameScores, test);
    //                     }
    //                     if (response.data.folder) {
    //                         fetchImages(response.data.folder, test);
    //                     }
    //                 } catch (error) {
    //                     console.error('Error fetching data from:', endpoint, error);
    //                     setResults(prev => ({ ...prev, [test]: { error: 'Failed to fetch data' } }));
    //                     setLoading(prev => ({ ...prev, [test]: false }));
    //                 }
    //             }));
    //     };

    //     fetchResults();
    // }, [location, navigate]);

    useEffect(() => {
        const fetchVideoAndAnalyze = async () => {
            try {
                console.log("GETTING VIDEO");
                const response = await axios.get(`https://frontendtovm.azurewebsites.net/api/getMP4AndName/${uuid}`);
                const videoData = response.data.videoData;
                const filename = response.data.filename;

                // Convert Base64 string back to binary form
                const byteCharacters = atob(videoData);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const videoBlob = new Blob([byteArray], { type: 'video/mp4' });

                // Create a new FormData object and append the video file with actual filename
                const formData = new FormData();
                formData.append('file', new File([videoBlob], filename));

                console.log("FormData content:", formData.get('file'));

                const endpoint = `https://frontendtovm.azurewebsites.net${testEndpoints.test_vf_df}`;
                setLoading(prev => ({ ...prev, test_vf_df: true }));
                try {
                    console.log("RUNNING TEST");
                    const analyzeResponse = await axios.post(endpoint, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                    setResults(prev => ({ ...prev, test_vf_df: analyzeResponse.data }));
                    setLoading(prev => ({ ...prev, test_vf_df: false }));

                    if (analyzeResponse.data.frameScores) {
                        prepareChartData(analyzeResponse.data.frameScores, 'test_vf_df');
                    }
                    if (analyzeResponse.data.folder) {
                        fetchImages(analyzeResponse.data.folder, 'test_vf_df');
                    }
                } catch (error) {
                    console.error('Error fetching data from:', endpoint, error);
                    setResults(prev => ({ ...prev, test_vf_df: { error: 'Failed to fetch data' } }));
                    setLoading(prev => ({ ...prev, test_vf_df: false }));
                }
            } catch (error) {
                console.error('Error fetching video:', error);
            }
        };

        fetchVideoAndAnalyze();
    }, [uuid]);
    

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 37) {
                goToPrevImage();
            } else if (event.keyCode === 39) {
                goToNextImage();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const fetchImages = async (folderName, test) => {
        let index = 1;
        let urls = [];
        while (true) {
            const imageUrl = `https://firebasestorage.googleapis.com/v0/b/forentify-website.appspot.com/o/${encodeURIComponent(folderName)}%2Fframe_${index}.png?alt=media`;
            try {
                await axios.get(imageUrl);
                urls.push(imageUrl);
                index++;
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    break;
                }
            }
        }
        setImageUrls(prev => ({ ...prev, [test]: urls }));
    };

    const prepareChartData = (frameScores, test, highlightIndex = -1) => {
        const frames = frameScores.map(score => score[0]);
        const confidenceScores = frameScores.map(score => score[1] * 100);
        const pointColors = frameScores.map((score, index) => {
            if (index === highlightIndex) {
                return 'rgba(0, 0, 0, 0.6)';
            }
            return score[2] === 'Authentic' ? 'rgba(75, 192, 192, 0.6)' : 'rgba(255, 99, 132, 0.6)';
        });
        const pointRadius = frameScores.map((score, index) => {
            return index === highlightIndex ? 7.5 : 5;
        });

        setChartData(prev => ({
            ...prev,
            [test]: {
                labels: frames,
                datasets: [
                    {
                        label: 'Manipulation Likelihood',
                        data: confidenceScores,
                        pointBackgroundColor: pointColors,
                        pointBorderColor: pointColors,
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        borderWidth: 1,
                        pointRadius: pointRadius,
                        pointHoverRadius: pointRadius,
                    }
                ],
            }
        }));
    };

    useEffect(() => {
        if (results[selectedTest] && results[selectedTest].frameScores) {
            prepareChartData(results[selectedTest].frameScores, selectedTest, currentImageIndex);
        }
    }, [currentImageIndex, results, selectedTest]);

    const goToNextImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageUrls[selectedTest].length);
    };

    const goToPrevImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex - 1 + imageUrls[selectedTest].length) % imageUrls[selectedTest].length);
    };

    useEffect(() => {
        setCurrentImageIndex(0);
    }, [selectedTest]);

    const totalFrames = results[selectedTest]?.frameScores?.length || 0;
    const manipulatedFrames = results[selectedTest]?.frameScores?.filter(frame => frame[1] > 0.5).length || 0;


    return (
        <div>
            <div className="home-container">
                <h1>Deepfake Analysis Report</h1>
            </div>
            <div style={{ display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", alignItems: "center" }}>
                <h2>Overview</h2>
                {chartData[selectedTest] && (
                    <h4>
                        Of {totalFrames} {totalFrames === 1 ? 'frame' : 'frames'} analyzed, {manipulatedFrames} {manipulatedFrames === 1 ? 'frame' : 'frames'} {manipulatedFrames === 1 ? 'was' : 'were'} likely manipulated.
                    </h4>
                )}
                {!chartData[selectedTest] && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <BounceLoader speedMultiplier={0.5} />
                        <div style={{ height: "0.75rem" }} />
                        <h4>Evaluating Video</h4>
                    </div>
                )}
                <br />
                <h2>Analyzed Media</h2>
                <div style={{ height: "0.75rem" }} />
                <div style={{ width: '738px', height: '414px', border: '1px solid black', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: "#F8F9FA" }}>
                    {mp4File && (
                        <video controls style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
                            <source src={mp4File} type="video/mp4" />
                        </video>
                    )}
                    {!mp4File && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <BounceLoader speedMultiplier={0.5} />
                            <div style={{ height: "0.75rem" }} />
                            <h4>Loading Video</h4>
                        </div>
                    )}
                </div>
                <br />
                <h2>Frame by Frame Analysis</h2>
                <div style={{ height: "0.75rem" }} />
                {imageUrls[selectedTest] && imageUrls[selectedTest].length > 0 && (
                    <div className="image-gallery">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="mr-1" style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }} onClick={goToPrevImage}><GrPrevious /></button>
                            <img src={imageUrls[selectedTest][currentImageIndex]} alt={`Frame ${currentImageIndex + 1}`} style={{ width: '738px', height: '414px' }} />
                            <button className="mr-1" style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }} onClick={goToNextImage}><GrNext /></button>
                        </div>
                        <br />
                        {results[selectedTest] && !results[selectedTest].error && chartData[selectedTest] && (
                            <div style={{ width: '800px', height: '500px' }}>
                                <ManipulationChart chartData={chartData[selectedTest]} />
                            </div>
                        )}
                    </div>
                )}
                {!imageUrls[selectedTest] || !imageUrls[selectedTest].length > 0 && (
                    <div style={{ width: '738px', height: '414px', border: '1px solid black', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: "#F8F9FA" }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <BounceLoader speedMultiplier={0.5} />
                            <div style={{ height: "0.75rem" }} />
                            <h4>Highlighting Suspect Pixels</h4>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Results;
