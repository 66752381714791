import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../GlobalStyles.css';
import { FaUpload } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

const Analyze = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [selectedTests, setSelectedTests] = useState({
        test_vf_df: true
    });
    const [videoURL, setVideoURL] = useState(null);
    const [isResultsLoading, setIsResultsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const uploadFileToBlob = async (file, uuid) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userEmail', 'username@email.com');
        formData.append('uuid', uuid);

        try {
            const response = await axios.post('https://frontendtovm.azurewebsites.net/api/uploadFiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Video file uploaded successfully:', response.data);

            // Upload text file with username
            const textFileContent = localStorage.getItem('username');
            const textFileName = `${textFileContent}${uuid}.txt`;
            const textFileBlob = new Blob([textFileContent], { type: 'text/plain' });

            const textFormData = new FormData();
            textFormData.append('file', textFileBlob, textFileName);
            textFormData.append('userEmail', 'username@email.com');
            textFormData.append('uuid', uuid);

            const textFileResponse = await axios.post('https://frontendtovm.azurewebsites.net/api/uploadFiles', textFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Text file uploaded successfully:', textFileResponse.data);

        } catch (error) {
            console.error('Error calling Flask API:', error);
        }
    };

    const handleGoClick = async () => {
        if (file) {
            const newUuid = uuidv4();
            // console.log(newUuid);
            try {
                setIsResultsLoading(true);
                await uploadFileToBlob(file, newUuid);
                navigate(`/results/${newUuid}`, { state: { file, tests: selectedTests } });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            if (uploadedFile.type !== 'video/mp4') {
                alert('Please upload a .mp4 file');
                return;
            }
            if (uploadedFile.size > 50 * 1024 * 1024) {
                alert('Please upload a file smaller than 50MB');
                return;
            }

            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                if (video.duration > 60) {
                    alert('Please upload a video shorter than 1 minute');
                    return;
                }
                const fileURL = URL.createObjectURL(uploadedFile);
                setVideoURL(fileURL);
                setFile(uploadedFile);
            };

            video.src = URL.createObjectURL(uploadedFile);
        }
    };

    const renderSignInPrompt = () => (
        <div>
            <div className="home-container">
                <div className="text-content" style={{ textAlign: 'center' }}>
                    <h1>Analyze</h1>
                    <br />
                    <h3>To detect manipulated media, please Sign In.</h3>
                    <br />
                    <Link to="/login" className="btn-link" style={{ textDecoration: 'none' }}>
                        <button className="btn-custom btn-charcoal-gray mr-1 mb-2">Sign In</button>
                    </Link>
                </div>
            </div>
        </div>
    );

    const renderMobileAnalyze = () => (
        <div>
            <div className="home-container">
                <div className="text-content" style={{ textAlign: 'center' }}>
                    <h1>Analyze</h1>
                    <br />
                    <h3>To detect manipulated media, please visit Forentify on a computer.</h3>
                </div>
            </div>
        </div>
    );

    const renderDesktopAnalyze = () => (
        <div>
            <div className="home-container">
                <h1>Analyze Video for Deepfake Manipulation</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <div>
                    {!videoURL && (
                        <div onClick={handleUploadClick} style={{
                            width: 820, height: 460, border: '1px solid black', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: "#F8F9FA", cursor: 'pointer'
                        }}>
                            <input
                                type="file"
                                accept="video/mp4"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <h3>Upload a .mp4 file</h3>
                            (Max: 1 Min, 50MB)
                            <br />
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                <FaUpload fontSize="3rem" />
                            </div>
                            <br /><br/><br/>
                            <h4><i>Frame-by-frame analysis will take 45 - 120 seconds to complete.</i></h4>
                            <br />
                        </div>
                    )}
                    {videoURL && (
                        <div>
                            <video key={videoURL} width="820" height="460" controls>
                                <source src={videoURL} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <br />
                            <input
                                type="file"
                                accept="video/mp4"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                    )}
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', textAlign: 'center' }}>
                        <button onClick={handleUploadClick} className="btn-custom btn-mid-gray mr-2">Upload</button>
                        <button onClick={handleGoClick} className="btn-custom btn-charcoal-gray">Analyze</button>
                    </div>
                    <br />
                    {isResultsLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                            <br/>
                            <BounceLoader />
                            <div style={{height: "0.75rem"}}/>
                            <h4>Uploading Video</h4>
                            <br />
                        </div>
                    )}

                </div>
            </div>
        </div>
    );

    return (
        <div>
            {isAuthenticated ? (isMobile ? renderMobileAnalyze() : renderDesktopAnalyze()) : renderSignInPrompt()}
        </div>
    );
};

export default Analyze;
