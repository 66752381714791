import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom'; // Make sure to import these
import "./HeaderFooter.css"
// import SignIn from '../google/SignIn'; // Adjust the path as necessary

function NavBar() {
  const activeStyle = { fontWeight: "bold" };  // Style for the active NavLink
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true'); // Check localStorage for authentication status

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
  };

  return (
    // changed navbar-expand-md to navbar-expand-lg
    <nav className="navbar navbar-expand-lg navbar-light" >

      <div className="container">
        <div className="container d-flex align-items-center">
          <Link className="navbar-brand navbar-text-color" to="/">
            <img src="/site_logo_forentify.png" alt="Forentify Logo" height="40" />{" "}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/analyze" style={({ isActive }) => isActive ? activeStyle : undefined}>
                  Analyze
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about" style={({ isActive }) => isActive ? activeStyle : undefined}>
                  About
                </NavLink>
              </li>
            </ul>
            <div className="ms-auto">
              {isAuthenticated ? (
                <div className="d-flex align-items-center">
                  <button className="btn-fixture btn-mid-gray ml-1" onClick={handleLogout}>
                    Sign Out
                  </button>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Link to="/login">
                    <button className="btn-fixture btn-charcoal-gray"> Sign In </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;