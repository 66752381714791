import React from 'react';
import "./HeaderFooter.css"

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <img src="/forentify_logo_white.png" alt="VerbaAI Logo" className="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;
