// ManipulationChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register the necessary components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, annotationPlugin);

const ManipulationChart = ({ chartData }) => {
    if (!chartData) {
        return <div>No data or loading...</div>;
    }

    return (
        <Line
            data={chartData}
            options={{
                responsive: true,
                plugins: {
                    title: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                label += `${parseFloat(context.parsed.y).toFixed(2)}%`;
                                return label;
                            },
                            title: function (context) {
                                const frameNumber = context[0].label;
                                return `Video Frame #${frameNumber}`;
                            }
                        },
                        titleFont: {
                            family: 'Afacad',
                            size: 20,
                            weight: '500'
                        },
                        bodyFont: {
                            family: 'Afacad',
                            size: 18,
                        }
                    },
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            font: {
                                family: 'Afacad',
                                size: 16,
                            },
                            usePointStyle: true,
                            generateLabels: () => {
                                return [
                                    {
                                        text: 'Authentic',
                                        fillStyle: 'rgba(75, 192, 192, 0.6)',
                                        strokeStyle: 'rgba(75, 192, 192, 0.6)',
                                        lineWidth: 1,
                                        pointStyle: 'circle',
                                        fontWeight: '500' // Added fontWeight
                                    },
                                    {
                                        text: '', // Spacer
                                        fillStyle: 'rgba(0, 0, 0, 0)', // Transparent color
                                        strokeStyle: 'rgba(0, 0, 0, 0)',
                                        lineWidth: 0,
                                        pointStyle: '', // No point style
                                    },
                                    {
                                        text: 'Manipulated',
                                        fillStyle: 'rgba(255, 99, 132, 0.6)',
                                        strokeStyle: 'rgba(255, 99, 132, 0.6)',
                                        lineWidth: 1,
                                        pointStyle: 'circle',
                                        fontWeight: '500' // Added fontWeight
                                    }
                                ];
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Video Frame",
                            font: {
                                family: 'Afacad',
                                size: 24,
                                weight: '500'
                            },
                            color: 'black',
                        },
                        ticks: {
                            font: {
                                family: 'Afacad',
                                size: 16
                            },
                            color: 'black',
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Manipulation Likelihood',
                            font: {
                                family: 'Afacad',
                                size: 24,
                                weight: '500'
                            },
                            color: 'black',
                        },
                        ticks: {
                            callback: function (value) {
                                return `${value}%`;
                            },
                            font: {
                                family: 'Afacad',
                                size: 16
                            },
                            color: 'black',
                            stepSize: 25 // This will skip every other tick
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 5, // default point radius
                        hoverRadius: 7.5 // radius when hovered (1.5 times the default)
                    }
                }
            }}
        />
    );
};

export default ManipulationChart;
