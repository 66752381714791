import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../GlobalStyles.css'; // Import the modified CSS file
import BounceLoader from 'react-spinners/BounceLoader';
import YouTube from 'react-youtube';

const videoId = 'xhC6TkiRv0c';

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getVideoOpts = (start) => ({
    // height: `${window.innerHeight * 0.5}px`,
    height: isMobile ? `${window.innerHeight * 0.3}px` : `${window.innerHeight * 0.7}px`,
    width: isMobile ? '100%' : '70%',
    playerVars: {
      autoplay: 0,
      start: start,
    },
  });

  const [loaderSize, setLoaderSize] = useState(0);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    if (imageContainerRef.current) {
      const containerWidth = imageContainerRef.current.offsetWidth;
      setLoaderSize(containerWidth * 0.5); // 75% of the container width
    }
  }, []);

  return (
    <div>
      <div className="home-container">
        <div className="content-flex-container">
          <div className="text-content">
            {!isMobile && (
              <div style={{ fontSize: "4.5rem", fontWeight: 500, lineHeight: 1 }}>
                Detect manipulated media, down to the pixel.
              </div>
            )}
            {isMobile && (
              <div style={{ fontSize: "3rem", fontWeight: 500, lineHeight: 1 }}>
                Detect manipulated media, down to the pixel.
              </div>
            )}
            <div style={{ height: '0.75rem' }} />
            <p>
              Analyze videos, images, and audio files. Uncover manipulations second-by-second, frame-by-frame, and pixel-by-pixel.
            </p>
            <div>
              <Link to="/about" className="btn-link" style={{ textDecoration: 'none' }}>
                <button className="btn-custom btn-charcoal-gray mr-1 mb-2">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="image-content" ref={imageContainerRef}>
            <div className="loader-container hide-on-mobile">
              <BounceLoader color="#000000" speedMultiplier={0.1} size={loaderSize} />
            </div>
          </div>
        </div>
      </div>
      {!isMobile && <br />}
      <div className="home-container" style={{ background: '#F8F9FA', borderRadius: '20px' }}>
        <div className="text-content" style={{ textAlign: 'center' }}>
          <h1 style={{ textAlign: 'center' }}>Our Mission: Protect our Information Environment</h1>
          <p style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            We've spent years researching <a href="https://www.disinfolab.wm.edu/" style={{ textDecoration: 'underline', color: 'inherit' }}>mis/disinformation</a> as directors of <a href="https://www.disinfolab.wm.edu/" style={{ textDecoration: 'underline', color: 'inherit' }}>W&M DisinfoLab</a>. Now, we're developing software to combat it.
            We're proud to be supported by <a href="https://www.startpeninsula.com/2023/11/09/winners-announced-for-start-peninsula-2023" style={{ textDecoration: 'underline', color: 'inherit' }}>Start Peninsula</a>,
            the <a href="https://www.wm.edu/offices/global-research/" style={{ textDecoration: 'underline', color: 'inherit' }}>Global Research Institute</a>,
            and <a href="https://www.microsoft.com/en-us/corporate-responsibility/democracy-forward?activetab=pivot1%3aprimaryr5" style={{ textDecoration: 'underline', color: 'inherit' }}>Microsoft Democracy Forward</a>.
          </p>
          <br />
          <img src="/supporters.png" alt="Our Supporters" className="supporter-image" />
        </div>
      </div>
      <br />
      <div className="home-container">
        <div className="text-content">
          <h1 style={{ textAlign: 'center', fontSize: '3rem' }}>Watch Forentify</h1>
          <div style={{ height: '0.75rem' }} />
          <h2 style={{ textAlign: 'center' }}>Detect a Deepfake</h2>
          <YouTube style={{ textAlign: 'center' }} videoId={videoId} opts={getVideoOpts(54)} />
          <br />
          <h2 style={{ textAlign: 'center' }}>Detect a Video Recording of a Deepfake</h2>
          <YouTube style={{ textAlign: 'center' }} videoId={videoId} opts={getVideoOpts(210)} />
          <br />
          <h2 style={{ textAlign: 'center' }}>Detect a Synthetic Voice</h2>
          <YouTube style={{ textAlign: 'center' }} videoId={videoId} opts={getVideoOpts(127)} />
          <br />
          <h2 style={{ textAlign: 'center' }}>Detect an Altered Background</h2>
          <YouTube style={{ textAlign: 'center' }} videoId={videoId} opts={getVideoOpts(273)} />
        </div>
      </div>
      <div className="home-container">
        <div className="text-content">
          <div>
            <div style={{ fontSize: isMobile ? "1.2rem" : "1.5rem" }}>
              <h1 style={{ textAlign: 'center', fontSize: '3rem', marginBottom: '1rem' }}>The Forentify Difference</h1>
              <div style={{ height: '0.75rem' }} />
              <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '15px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <thead>
                  <tr style={{ backgroundColor: '#222222' }}>
                    <th style={{ border: '1px solid', color: 'white', fontWeight: 500, padding: '16px', textAlign: 'center', width: '50%' }}>
                      <h2>Other Platforms</h2>
                    </th>
                    <th style={{ border: '1px solid', color: 'white', fontWeight: 500, padding: '16px', textAlign: 'center', width: '50%' }}>
                      <h2>Forentify</h2>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: '#F8F9FA' }}>
                    <td style={{ border: '1px solid #ddd', padding: '16px' }}>
                      <strong style={{ fontWeight: 500 }}>Black Box Predictions:</strong> "XYZ% chance this media is fake."
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '16px' }}>
                      <strong style={{ fontWeight: 500 }}>Fully Explainable Predictions:</strong> Manipulations are flagged down to the second, frame, and pixel.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '16px' }}>
                      <strong style={{ fontWeight: 500 }}>Vague Classifications:</strong> Media is classified as "real" or "fake."
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '16px' }}>
                      <strong style={{ fontWeight: 500 }}>Specific Classifications:</strong> Fake media is classified by the precise manipulation present, e.g. "deepfake," or "altered background."
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#F8F9FA' }}>
                    <td style={{ border: '1px solid #ddd', padding: '16px', fontWeight: 500 }}>Support Deepfake Detection</td>
                    <td style={{ border: '1px solid #ddd', padding: '16px', fontWeight: 500 }}>Supports Deepfake and Cheapfake Detection</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '16px', fontWeight: 500 }}>Support Images and/or Audio Detection</td>
                    <td style={{ border: '1px solid #ddd', padding: '16px', fontWeight: 500 }}>Supports Images, Video, and Audio Detection</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
