import { BrowserRouter as Router, Routes, Route, Link, NavLink } from "react-router-dom";
import NavBar from "./components/fixtures/NavBar";
import Home from "./components/pages/Home"
import Demo from "./components/pages/Demo";
import About from "./components/pages/About";
import Analyze from "./components/pages/Analyze";
import Results from "./components/pages/Results";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/fixtures/HeaderFooter.css";
import { UserProvider } from "./contexts/UserContext";
import Footer from './components/fixtures/Footer'; // adjust the path based on where you put the file
import "./index.css"
// import Contact from "./components/pages/Contact";
import Login from "./components/pages/Login";
import ToS from "./components/pages/ToS";


function App() {
  return (
    <UserProvider>
      {/* <FirestoreProvider> */}
        {/* <TableDataProvider> */}
          <Router>
            <>
              <div id="page-container"> {/* Page container */}
                <NavBar />
                <div
                  style={{
                    margin: "1rem auto",
                    maxWidth: 1500,
                    width: "98%",
                    padding: "0 .25rem",
                    overflowX: "hidden",
                  }}
                >
                  <div id="content-wrap"> {/* Content wrap */}

                    <Routes>
                      <Route
                        path="/"
                        element={
                          <>
                            <Home />
                          </>
                        }
                      />
                      {/* <Route path="/demo" element={<Demo />} /> */}
                      <Route path="/analyze" element={<Analyze />} />
                      <Route path="/results/:uuid" element={<Results />} />
                      {/* <Route path="/contact" element={<Contact />} /> */}
                      <Route path="/login" element={<Login />} />
                      <Route path="/terms" element={<ToS />} />
                      <Route path="/about" element={<About />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </>
            <Footer />
          </Router>
        {/* </TableDataProvider> */}
      {/* </FirestoreProvider> */}
    </UserProvider>
  );
}

export default App;
