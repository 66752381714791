import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../GlobalStyles.css'; // Import the modified CSS file
import StartPen from '../../assets/start_pen.png';
import BounceLoader from 'react-spinners/BounceLoader';


const ToS = () => {

    return (
        <div>
            <div className="home-container">
                <div className="text-content">
                    <h1 style={{ textAlign: 'center' }}>Forentify Terms of Service</h1>
                    <h2 style={{ textAlign: 'center' }}>Closed Beta</h2>
                    <br />
                    <p>
                        By accessing Forentify ("Software"), you ("Client") agree to the following terms and conditions provided by VerbaAI LLC ("Provider").
                        <br /><br />
                        <h4>1. License Grant</h4>
                        Provider grants to Client a non-exclusive, non-transferable, limited license to use the software known as Forentify solely for evaluation purposes during the Trial Period.
                        <br /><br />

                        <h4>2. Trial Period</h4>
                        The trial period shall commence at initial sign-up and conclude after five days.
                        <br /><br />

                        <h4>3. Usage Restrictions</h4>
                        Client agrees not to:<br />
                        a. Use the Software for any purpose other than evaluation.<br />
                        b. Distribute, lease, sublicense, or otherwise transfer the Software to any third party.<br />
                        c. Reverse engineer, decompile, or disassemble the Software.
                        <br /><br />

                        <h4>4. Proprietary Rights</h4>
                        The Software and all related materials are the exclusive property of Provider.
                        <br /><br />

                        <h4>5. Confidentiality</h4>
                        Client acknowledges that the Software contains confidential and proprietary information of Provider. Client agrees to maintain the confidentiality of the Software and not disclose it to any third party.
                        <br /><br />

                        <h4>6. Limitation of Liability</h4>
                        In no event shall Provider be liable for any damages whatsoever arising out of or in connection with the use or performance of the Software.
                        <br /><br />

                        <h4>7. Termination</h4>
                        Provider may terminate this Agreement at any time by providing written notice to Client. Upon termination, Client shall cease all use of the Software.

                        <br /><br />
                        <h4>8. Governing Law</h4>
                        This Agreement shall be governed by and construed in accordance with the laws of the State of Virginia, without regard to its conflict of laws principles.
                        <br /><br />
                        By using the Software, you agree to these Terms of Service.
                    </p>
                </div>
            </div>
        </div >
    );
}
export default ToS;
