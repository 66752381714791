import React, { useState } from 'react';
import '../GlobalStyles.css'; // Import the modified CSS file
import { NavLink, Link } from 'react-router-dom'; // Make sure to import these
import { Alert } from 'react-bootstrap';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const validCredentials = [
            { username: 'jeremy', password: 'DisinfoLabSolutions2024!'},
            { username: 'aaraj', password: 'DisinfoLabSolutions2024!'},
            // { username: 'bginsburg', password: 'A2$cure!'},
            // { username: 'DCW2050', password: 'G7@wP3nZ' },
            // { username: 'MBN', password: 'aB2#x9Zq' },
            // { username: 'MicrosoftDF', password: 'A8$bT4z*' },
            // { username: 'MWXPrize', password: 'G7p$w2Qx' },
            // { username: 'AccreteAI', password: 'D7f$T4mZ' },
            // { username: 'LWVVirginia', password: '8Yx!2pKz' },
            // { username: 'UMBC', password: 'R7m!zP9q' },
            // { username: 'GRI', password: 'A7k$pZ2x' },
            // { username: 'danny', password: 'aB3$dEfG' },
            // { username: 'W&M', password: 'Ab3$d1Fz' },
            // Add more username-password pairs as needed
        ];

        const isAuthenticated = validCredentials.some(
            (cred) => cred.username === username && cred.password === password
        );

        if (isAuthenticated && agreeToTerms) {
            localStorage.setItem('isAuthenticated', 'true');
            localStorage.setItem('username', username)
            setIsAuthenticated(true);
        } else if (!agreeToTerms) {
            alert('You must agree to the terms of service');
        } else {
            alert('Incorrect username or password');
        }
    };

    if (isAuthenticated) {
        return (
            <div className="home-container">
                <div className="text-content" style={{ textAlign: 'center' }}>
                    <h1>Welcome to Forentify!</h1>
                    <br/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/analyze" className="btn-link">
                            <button className="btn-custom btn-charcoal-gray">Get Started</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="home-container">
            <div className="text-content" style={{ textAlign: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>Sign in to Forentify</h1>
                <br />
                <div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <h4>Username</h4>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <br />
                        <div>
                            <h4>Password</h4>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <br />
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                />
                                {" "}I agree to the <Link to="/terms" style={{ color: 'black', textDecoration: 'underline' }}>Terms of Service</Link>.
                            </label>
                        </div>
                        <br />
                        <button className="btn-custom btn-charcoal-gray" type="submit">
                            Sign In
                        </button>
                    </form>
                </div>
                <br /><br />
                <h2>Want to try Forentify?</h2>
                <div style={{ height: "0.75rem" }} />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/about" className="btn-link">
                        <button className="btn-custom btn-mid-gray">Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
